var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1" }, [
        _c(
          "form",
          {
            ref: "formFilter",
            staticClass: "m-0",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.getData.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: { "is-invalid": _vm.$v.filters.rangeDate.$error },
                  },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _c("i", {
                        staticClass: "text-danger bi bi-record-circle",
                      }),
                      _vm._v(" Período "),
                    ]),
                    _c("flat-pickr", {
                      staticClass: "form-control",
                      attrs: {
                        config: {
                          mode: "range",
                          altInput: true,
                          dateFormat: "Y-m-d",
                          altFormat: "d/m/Y",
                          locale: "pt",
                        },
                      },
                      model: {
                        value: _vm.filters.rangeDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "rangeDate", $$v)
                        },
                        expression: "filters.rangeDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Preferência"),
                    ]),
                    _c(
                      "v-select",
                      {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: ["Presencial", "Cursos Online", "Live"],
                          searchable: false,
                        },
                        model: {
                          value: _vm.filters.preferToStudy,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "preferToStudy", $$v)
                          },
                          expression: "filters.preferToStudy",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Sessão")]),
                    _c(
                      "v-select",
                      {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: [
                            "Concurso",
                            "Presencial",
                            "Live",
                            "Passaporte",
                          ],
                          searchable: false,
                        },
                        model: {
                          value: _vm.filters.pageType,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "pageType", $$v)
                          },
                          expression: "filters.pageType",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Turno")]),
                    _c(
                      "v-select",
                      {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: _vm.optionsShifts,
                          searchable: false,
                        },
                        model: {
                          value: _vm.filters.shift,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "shift", $$v)
                          },
                          expression: "filters.shift",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Unidade")]),
                    _c(
                      "v-select",
                      {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: _vm.optionsUnits,
                          searchable: false,
                        },
                        model: {
                          value: _vm.filters.unit,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "unit", $$v)
                          },
                          expression: "filters.unit",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0" },
                  [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Formulário")]),
                    _c(
                      "v-select",
                      {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "code",
                          options: [
                            { code: 1, title: "Popup" },
                            { code: 0, title: "Página" },
                          ],
                          searchable: false,
                        },
                        model: {
                          value: _vm.filters.popup,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "popup", $$v)
                          },
                          expression: "filters.popup",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "form-group mb-md-0" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("E-mail")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.email,
                        expression: "filters.email",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "search",
                      placeholder: "E-mail",
                      id: "filterInput",
                    },
                    domProps: { value: _vm.filters.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.filters, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Melhor Horário"),
                    ]),
                    _c(
                      "v-select",
                      {
                        attrs: {
                          label: "title",
                          "item-text": "title",
                          "item-value": "title",
                          options: _vm.optionsTimes,
                          searchable: false,
                        },
                        model: {
                          value: _vm.filters.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "time", $$v)
                          },
                          expression: "filters.time",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v(" Nenhum registro encontrado ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-1" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-block",
                    staticStyle: { "margin-top": "20px" },
                    attrs: { type: "submit", disabled: _vm.btnSearch },
                  },
                  [
                    _vm.btnSearch
                      ? _c(
                          "div",
                          [
                            _c("b-spinner", {
                              attrs: { label: "Loading...", small: "" },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "SearchIcon", size: "16" },
                            }),
                          ],
                          1
                        ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.fields,
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              "sticky-header": "",
              striped: "",
              hover: "",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(first_name)",
                fn: function (data) {
                  return [
                    data.item.customer_name
                      ? _c(
                          "b-link",
                          {
                            attrs: {
                              to: {
                                name: "customers-edit",
                                params: { uuid: data.item.customer_uuid },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "bi bi-box-arrow-up-right",
                            }),
                            _vm._v(" " + _vm._s(data.item.customer_name) + " "),
                          ]
                        )
                      : _c("span", [
                          _vm._v(" " + _vm._s(data.item.first_name) + " "),
                        ]),
                  ]
                },
              },
              {
                key: "cell(category)",
                fn: function (data) {
                  return [
                    data.item.product
                      ? _c("span", [
                          _vm._v(
                            " " + _vm._s(data.item.product.category) + " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(product)",
                fn: function (data) {
                  return [
                    data.item.product
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://" +
                                _vm.user.school.domain +
                                "/" +
                                data.item.product.slug,
                              target: "_blank",
                              title: data.item.product.title,
                            },
                          },
                          [
                            data.item.product.image
                              ? _c("b-avatar", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.bottom",
                                      value: data.item.product.category,
                                      expression: "data.item.product.category",
                                      modifiers: { hover: true, bottom: true },
                                    },
                                  ],
                                  attrs: {
                                    rounded: "sm",
                                    size: "32",
                                    src: data.item.product.image,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    data.item.created_at
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datePT")(data.item.created_at, true)
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor",
                          attrs: { icon: "EyeIcon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.modalDetails(data.item.uuid)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.currentPage,
              "per-page": _vm.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "showLead",
                "modal-class": "modal-edit",
                title: "Detalhes do lead",
                "hide-footer": "",
                centered: "",
                size: "md",
              },
            },
            [
              _c("b-card-text", [
                _c("h5", { staticClass: "mb-2" }, [
                  _c("strong", [_vm._v("Página:")]),
                  _vm._v(" " + _vm._s(_vm.item.page_title)),
                ]),
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                      _c("h6", { staticClass: "mb-2 text-primary" }, [
                        _vm._v("Dados do lead"),
                      ]),
                      _c("h6", { staticClass: "mb-25" }, [
                        _c("strong", [_vm._v("Nome:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.item.first_name) +
                            " " +
                            _vm._s(_vm.item.last_name) +
                            " "
                        ),
                      ]),
                      _c("p", { staticClass: "card-text mb-25" }, [
                        _c("strong", [_vm._v("Contato:")]),
                        _vm._v(" " + _vm._s(_vm.item.phone) + " "),
                      ]),
                      _c("p", { staticClass: "card-text mb-25" }, [
                        _c("strong", [_vm._v("E-mail:")]),
                        _vm._v(" " + _vm._s(_vm.item.email) + " "),
                      ]),
                    ]),
                    _c("b-col", { attrs: { md: "6", cols: "12" } }, [
                      _c("div", [
                        _c("h6", { staticClass: "mb-2 text-primary" }, [
                          _vm._v("Interesse"),
                        ]),
                        _c("h6", { staticClass: "mb-25" }, [
                          _c("strong", [_vm._v("Sessão:")]),
                          _vm._v(" " + _vm._s(_vm.item.page_type) + " "),
                        ]),
                        _c("p", { staticClass: "card-text mb-25" }, [
                          _c("strong", [_vm._v("Unidade:")]),
                          _vm._v(" " + _vm._s(_vm.item.unit) + " "),
                        ]),
                        _c("p", { staticClass: "card-text mb-25" }, [
                          _c("strong", [_vm._v("Turno:")]),
                          _vm._v(" " + _vm._s(_vm.item.shift) + " "),
                        ]),
                        _c("p", { staticClass: "card-text mb-25" }, [
                          _c("strong", [_vm._v("Horário:")]),
                          _vm._v(" " + _vm._s(_vm.item.time) + " "),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-12" }, [_c("hr")]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("strong", [_vm._v("Data:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("datePT")(_vm.item.created_at, true)) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }