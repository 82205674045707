<template>
  <div>
    <b-card class="mb-1">
      <form class="m-0" ref="formFilter" @submit.prevent="getData">
        <div class="form-row">
          <div class="col-md-3">
            <div
              class="form-group"
              :class="{ 'is-invalid': $v.filters.rangeDate.$error }"
            >
              <label for="">
                <i class="text-danger bi bi-record-circle"></i> Período
              </label>
              <flat-pickr
                v-model="filters.rangeDate"
                class="form-control"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="">Preferência</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="['Presencial', 'Cursos Online', 'Live']"
                v-model="filters.preferToStudy"
                :searchable="false"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label for="">Sessão</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="['Concurso', 'Presencial', 'Live', 'Passaporte']"
                v-model="filters.pageType"
                :searchable="false"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <label for="">Turno</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsShifts"
                v-model="filters.shift"
                :searchable="false"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <label for="">Unidade</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="optionsUnits"
                v-model="filters.unit"
                :searchable="false"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <label for="">Formulário</label>
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                :options="[
                  { code: 1, title: 'Popup' },
                  { code: 0, title: 'Página' },
                ]"
                v-model="filters.popup"
                :searchable="false"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <label for="">E-mail</label>
              <input
                type="search"
                class="form-control"
                placeholder="E-mail"
                id="filterInput"
                v-model="filters.email"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <label for="">Melhor Horário</label>
              <v-select
                label="title"
                item-text="title"
                item-value="title"
                :options="optionsTimes"
                v-model="filters.time"
                :searchable="false"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>
          <div class="col-md-1">
            <button
              type="submit"
              class="btn btn-secondary btn-block"
              style="margin-top: 20px"
              :disabled="btnSearch"
            >
              <div v-if="btnSearch">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor"
                  size="16"
                />
              </div>
            </button>
          </div>
        </div>
      </form>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="fields"
        show-empty
        empty-text="Nenhum registro encontrado"
        sticky-header
        striped
        hover
      >
        <template #cell(first_name)="data">
          <b-link
            v-if="data.item.customer_name"
            :to="{
              name: 'customers-edit',
              params: { uuid: data.item.customer_uuid },
            }"
          >
            <i class="bi bi-box-arrow-up-right"></i>
            {{ data.item.customer_name }}
          </b-link>
          <span v-else>
            {{ data.item.first_name }}
          </span>
        </template>

        <template #cell(category)="data">
          <span v-if="data.item.product">
            {{ data.item.product.category }}
          </span>
        </template>

        <template #cell(product)="data">
          <a
            :href="`https://${user.school.domain}/${data.item.product.slug}`"
            target="_blank"
            v-if="data.item.product"
            :title="data.item.product.title"
          >
            <b-avatar
              v-if="data.item.product.image"
              v-b-tooltip.hover.bottom="data.item.product.category"
              rounded="sm"
              size="32"
              :src="data.item.product.image"
            />
          </a>
        </template>

        <template #cell(created_at)="data">
          <span v-if="data.item.created_at">
            {{ data.item.created_at | datePT(true) }}
          </span>
        </template>

        <template #cell(action)="data">
          <div class="d-flex-center">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="modalDetails(data.item.uuid)"
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
      />

      <b-modal
        id="showLead"
        modal-class="modal-edit"
        title="Detalhes do lead"
        hide-footer
        centered
        size="md"
      >
        <b-card-text>
          <h5 class="mb-2"><strong>Página:</strong> {{ item.page_title }}</h5>
          <div class="form-row">
            <b-col cols="12" md="6">
              <h6 class="mb-2 text-primary">Dados do lead</h6>
              <h6 class="mb-25">
                <strong>Nome:</strong>
                {{ item.first_name }} {{ item.last_name }}
              </h6>
              <p class="card-text mb-25">
                <strong>Contato:</strong>
                {{ item.phone }}
              </p>
              <p class="card-text mb-25">
                <strong>E-mail:</strong>
                {{ item.email }}
              </p>
            </b-col>

            <b-col md="6" cols="12">
              <div>
                <h6 class="mb-2 text-primary">Interesse</h6>
                <h6 class="mb-25">
                  <strong>Sessão:</strong>
                  {{ item.page_type }}
                </h6>
                <p class="card-text mb-25">
                  <strong>Unidade:</strong>
                  {{ item.unit }}
                </p>
                <p class="card-text mb-25">
                  <strong>Turno:</strong>
                  {{ item.shift }}
                </p>
                <p class="card-text mb-25">
                  <strong>Horário:</strong>
                  {{ item.time }}
                </p>
              </div>
            </b-col>
            <div class="col-12">
              <hr />
            </div>
            <div class="col-12">
              <strong>Data:</strong> {{ item.created_at | datePT(true) }}
            </div>
          </div>
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BMedia,
  BAvatar,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  VBTooltip,
  BTooltip,
  BPagination,
  BModal,
  BFormGroup,
  BForm,
  BCardText,
  BFormRow,
  BSpinner,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BSpinner,
    CustomPaginateTable,
    BFormRow,
    VBTooltip,
    flatPickr,
    BTooltip,
    BCardText,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BForm,
    vSelect,
    BLink,
  },
  data() {
    return {
      optionsTimes: [
        { title: "Agora"},
        { title: "Manhã (08:00 - 11:59)"},
        { title: "Tarde (12:00 - 17:59)"},
        { title: "Noite (18:00 - 20:30)"},
      ],
      filters: {
        name: "",
        email: "",
        origin: "lead",
        rangeDate: "",
        pageType: "",
        preferToStudy: "",
        unit: "",
        popup: "",
        time: "",
      },
      filtersSearch: {
        name: "",
        email: "",
        origin: "lead",
        rangeDate: "",
        pageType: "",
        preferToStudy: "",
        unit: "",
        popup: "",
        time: "",
      },
      submitedFilter: false,
      submited: false,
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      fields: [
        {
          key: "first_name",
          label: "Nome",
          thStyle: "width: 300px",
        },
        {
          key: "prefer_to_study",
          label: "Preferência",
          class: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "product",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
        {
          key: "category",
          label: "Categoria",
          class: "text-center",
          thStyle: "width: 150px",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "action",
          label: "",
          class: "text-center",
          thStyle: "width: 50px",
        },
      ],
      dataItems: [],
      optionsProductCategories: [],
      optionsUnits: [],
      optionsShifts: [],
      item: {
        uuid: "",
        page_title: "",
        page_type: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        popup: "",
        prefer_to_study: "",
        unit: "",
        shift: "",
        time: "",
        status: "",
        created_at: "",
        origin: "",
      },
      btnSearch: false,
      user: "",
    };
  },
  validations: {
    filters: {
      rangeDate: {
        required,
        minLength: minLength(5),
      },
    },
  },
  created() {
    this.getData();
    this.user = JSON.parse(localStorage.getItem("userData"));
  },
  methods: {
    async fetchShifts() {
      await this.$store.dispatch("Shift/forSelect").then((res) => {
        this.optionsShifts = res;
      });
    },
    async fetchProductCategories() {
      this.optionsProductCategories = [];
      this.$store.dispatch("Product/categories").then((res) => {
        this.optionsProductCategories = res.data;
      });
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },
    modalDetails(uuid) {
      this.$store.dispatch("Customer/lead", uuid).then((res) => {
        this.item = res;
      });

      this.item.uuid = uuid;
      this.$bvModal.show("showLead");
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Você não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, tenho!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: "Excluído com sucesso!",
            text: "Operação realizada com sucesso!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    async getData() {
      this.filtersSearch = {
          preferToStudy: this.filters.preferToStudy ?? "",
          rangeDate: this.filters.rangeDate,
          popup: this.filters.popup?.code ?? "",
          shift: this.filters.shift?.title ?? "",
          unit: this.filters.unit?.title ?? "",
          pageType: this.filters.pageType ?? "",
          name: this.filters.name ?? "",
          email: this.filters.email ?? "",
          origin: this.filters.origin,
          time: this.filters.time?.title ?? "",
        };

      this.$store
        .dispatch("Customer/leads", {
          currentPage: this.currentPage,
          perPage: this.perPage,
          filters: this.filtersSearch,
        })
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    async fetchUnits() {
      this.optionsUnits = await this.$store.dispatch("Unit/forSelect");
    },
  },
  mounted() {
    this.fetchProductCategories();
    this.fetchUnits();
    this.fetchShifts();

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:export-table", () => {
      this.$swal({
        title: "Exportar Leads",
        text: "Enviaremos para o seu e-mail e WhatsApp, o link para você baixar o relatório de captação de leads.",
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Gerar relatório",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Customer/exportLeads", {
            currentPage: this.currentPage,
            perPage: this.perPage,
            filters: this.filtersSearch,
          });
        }
      });
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
